import configData from '../data/config.json';


export class Configuration {
    fileName: string;
    displayName: string;
    width: number;
    height: number;

    constructor(data: any) {
        this.fileName = data.fileName;
        this.displayName = data.displayName;
        this.width = data.width;
        this.height = data.height;
    }
}

const configurations: Configuration[] = configData.map((data: any) => new Configuration(data));

export default configurations;
