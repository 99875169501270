import {ResizeResult} from './resizer';
import JSZip from 'jszip';
import contentsJson from '../data/Contents';
import * as FileSaver from 'file-saver';


let zip: any;


export function generateZip(results: ResizeResult[]) {

    zip = new JSZip();
    const images = zip.folder('LaunchImage.launchimage');
    images.file('Contents.json', JSON.stringify(contentsJson));

    results.map(result => images.file(result.configuration.fileName, result.blob));
}

export function downloadZip() {
    if (zip) {
        zip.generateAsync({ type: 'blob' })
            .then((content: Blob) => FileSaver.saveAs(content, 'LaunchImages.zip'));
    }
}
