import configurations, {Configuration} from './config';
import CIP from 'canvas_image_processing';
import * as Q from 'q';


const ImageSize = 4096;


export class ResizeResult {
    imageBase64: string;
    blob: Blob;
    configuration: Configuration;

    constructor(blog: Blob, imageBase64: string, configuration: Configuration) {
        this.blob = blog;
        this.imageBase64 = imageBase64;
        this.configuration = configuration;
    }
}


export default function (imageBase64: string) {
    const q = Q.defer();

    const transformations = configurations.map(configuration => resize(imageBase64, configuration));
    Q.all(transformations).then((results) => q.resolve(results));

    return q.promise;
}

function resize(imageBase64: string, configuration: Configuration) {
    const q = Q.defer();

    scale(imageBase64, configuration)
        .then((imageBase64: string) => crop(imageBase64, configuration))
        .then((imageBase64: string) => fetch(imageBase64)
            .then(image => image.blob())
            .then(blob => q.resolve(new ResizeResult(blob, imageBase64, configuration))));

    return q.promise;
}

function scale(base64image: string, configuration: Configuration) {
    const maxSize = Math.max(configuration.width, configuration.height);
    return CIP.resizeImage64(base64image, maxSize, maxSize);
}

function crop(base64image: string, configuration: Configuration) {
    const maxSize = Math.max(configuration.width, configuration.height);
    const x = (maxSize - configuration.width) / 2;
    const y = (maxSize - configuration.height) / 2;
    return CIP.cropImage64(base64image, x, y, configuration.width, configuration.height);
}
