import {ResizeResult} from './resizer';

export function generateThumbs(results: ResizeResult[]) {
    const iphoneLaunchImagesContainer: HTMLDivElement | null = document.querySelector('#iphone-launch-images-container');
    const ipadLaunchImagesContainer: HTMLDivElement | null = document.querySelector('#ipad-launch-images-container');
    if (!iphoneLaunchImagesContainer || !ipadLaunchImagesContainer) return;

    removeExistingThumbs(iphoneLaunchImagesContainer);
    removeExistingThumbs(ipadLaunchImagesContainer);

    results.forEach(result => {
        const container = isIPhone(result)
            ? iphoneLaunchImagesContainer
            : ipadLaunchImagesContainer;
        const thumbContainer = createThumbContainer(container);
        createThumbImage(result, thumbContainer);
        createThumbLabel(result, thumbContainer);
    });
}

function removeExistingThumbs(container: HTMLDivElement) {
    while (container.firstChild) {
        container.removeChild(container.firstChild);
    }
}

function createThumbContainer(container: HTMLDivElement) {
    const div = document.createElement('div');
    div.classList.add('launch-image-container');
    container.appendChild(div);
    return div;
}

function createThumbImage(result: ResizeResult, container: HTMLDivElement) {
    const img = document.createElement('img');
    img.src = result.imageBase64;
    img.classList.add('launch-image-thumb');
    let size = isIPhone(result) ? 120 : 240;
    if (result.configuration.width < result.configuration.height)
        img.width = size;
    else
        img.height = size;
    container.appendChild(img);
}

function createThumbLabel(result: ResizeResult, container: HTMLDivElement) {
    const div = document.createElement('div');
    div.classList.add('launch-image-label');
    div.innerText = result.configuration.displayName;
    container.appendChild(div);
}

function isIPhone(result: ResizeResult) {
    return result.configuration.fileName.startsWith('iphone');
}
