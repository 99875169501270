import resizer, {ResizeResult} from './resizer';
import {generateThumbs} from './thumbs';
import {downloadZip, generateZip} from './zip';


function handleFileSelectorOnChange() {
    if (!fileSelector) return;
    if (!fileSelector.files) return;

    hideContainer(selectedLaunchImageContainer);
    hideContainer(launchImagesContainer);
    if (spinnerContainer) spinnerContainer.style.display = ''; // TODO animate

    const imageFile = fileSelector.files[0];

    const reader = new FileReader();
    reader.onloadend = () => onImageFileLoaded(reader.result as string);
    reader.readAsDataURL(imageFile);
}

function onImageFileLoaded(imageBase64: string) {

    showSelectedLaunchImage(imageBase64);
    scrollToContainer(launchImageContainer);

    resizer(imageBase64).then((results: ResizeResult[]) => {
        generateZip(results);
        generateThumbs(results);
        showContainer(launchImagesContainer);
        hideContainer(spinnerContainer);
        scrollToContainer(launchImagesContainer);
        showContainer(donateContainer);
    });
}

function showSelectedLaunchImage(imageBase64: string) {
    if (selectedLaunchImage) selectedLaunchImage.src = imageBase64;
    showContainer(selectedLaunchImageContainer); // TODO improve this by animating only when image is loaded
}

const handleHideDisclaimerButtonOnClick = () => hideContainer(disclaimerContainer);

const handleDownloadPngTemplateButtonOnClick = () => window.open('templates/Launch Image Template.png');
const handleDownloadSketchTemplateButtonOnClick = () => window.open('templates/Launch Image Template.sketch');

const handleShowDisclaimerButtonOnClick = (event: MouseEvent) => {
    event.preventDefault();
    showContainer(disclaimerContainer);
};

const handleHideDonateButtonOnClick = (event: MouseEvent) => {
    event.preventDefault();
    hideContainer(donateContainer);
};

const handleDownloadLaunchImagesButtonOnClick = () => downloadZip();

function hideContainer(container: HTMLDivElement | null) {
    if (!container) return;
    container.style.display = 'none';
}

function showContainer(container: HTMLDivElement | null) {
    if (!container) return;
    container.style.display = '';
}

function scrollToContainer(container: HTMLDivElement | null) {
    if (container) container.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
    });
}


const fileSelector: HTMLInputElement | null = document.querySelector('#file-selector');
const downloadPngTemplateButton: HTMLButtonElement | null = document.querySelector('#download-png-template');
const downloadSketchTemplateButton: HTMLButtonElement | null = document.querySelector('#download-sketch-template');
const downloadLaunchImagesButton: HTMLButtonElement | null = document.querySelector('#download-launch-images');
const selectedLaunchImageContainer: HTMLDivElement | null = document.querySelector('#selected-launch-image-container');
const selectedLaunchImage: HTMLImageElement | null = document.querySelector('#selected-launch-image');
const launchImagesContainer: HTMLDivElement | null = document.querySelector('#launch-images-container');
const launchImageContainer: HTMLDivElement | null = document.querySelector('#launch-image-container');
const spinnerContainer: HTMLDivElement | null = document.querySelector('#spinner-container');
const disclaimerContainer: HTMLDivElement | null = document.querySelector('#disclaimer-container');
const hideDisclaimerButton: HTMLButtonElement | null = document.querySelector('#hide-disclaimer');
const showDisclaimerButton: HTMLAnchorElement | null = document.querySelector('#show-disclaimer');
const donateContainer: HTMLDivElement | null = document.querySelector('#donate-container');
const hideDonateButton: HTMLAnchorElement | null = document.querySelector('#hide-donate');

if (fileSelector) fileSelector.onchange = handleFileSelectorOnChange;
if (downloadPngTemplateButton) downloadPngTemplateButton.onclick = handleDownloadPngTemplateButtonOnClick;
if (downloadSketchTemplateButton) downloadSketchTemplateButton.onclick = handleDownloadSketchTemplateButtonOnClick;
if (downloadLaunchImagesButton) downloadLaunchImagesButton.onclick = handleDownloadLaunchImagesButtonOnClick;
if (hideDisclaimerButton) hideDisclaimerButton.onclick = handleHideDisclaimerButtonOnClick;
if (showDisclaimerButton) showDisclaimerButton.onclick = handleShowDisclaimerButtonOnClick;
if (hideDonateButton) hideDonateButton.onclick = handleHideDonateButtonOnClick;

hideContainer(selectedLaunchImageContainer);
hideContainer(launchImagesContainer);
hideContainer(spinnerContainer);
hideContainer(disclaimerContainer);
hideContainer(donateContainer);
